//=require ./../inc/light.js
//=require ./../inc/regular.js
//=require ./../inc/solid.js
//=require ./../inc/fontawesome.js
//=require popper.js/dist/umd/popper.js
//=require mustache/mustache.js
//=require jquery/dist/jquery.js
//=require bootstrap/dist/js/bootstrap.bundle.js
//=require bootstrap-select/js/bootstrap-select.js
//=require bootstrap-select/js/i18n/defaults-de_DE.js
//=require bootstrap-datepicker/dist/js/bootstrap-datepicker.js
//=require bootstrap-datepicker/dist/locales/bootstrap-datepicker.de.min.js
//=require bootstrap-fileinput/js/fileinput.js
//=require js-cookie/src/js.cookie.js
//=require ./../inc/bootstrap-extra.js
//=require parsleyjs/dist/parsley.js
//=require parsleyjs/dist/i18n/de.js
//=require parsleyjs/dist/i18n/de.extra.js

$('[data-toggle="popover"]').popover();

function isUrlProtocol(url)
{
    var r = new RegExp('^(?:[a-z]+:)?//', 'i');
    return r.test(url);
}

$('.js-link-protocol-fallback').click(function ()
{
    var url = $(this).attr('href');
    if (!isUrlProtocol(url))
    {
        $(this).attr('href', 'http://' + url);
    }
});
$(".js-set-back-url").click(function ()
{
    Cookies.set('backUrl', window.location.href);
});


$('.selectpicker').selectpicker();

$('.datepicker').datepicker({
    format: "dd.mm.yyyy",
    weekStart: 1,
    language: "de",
    zIndexOffset: 1050,
    enableOnReadonly: false
});
